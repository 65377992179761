import React, { useEffect } from "react"

import Hero from "layout/Hero"
import Container from "layout/Container"
import HomeMediaContainer from "./HomeMediaContainer"

import savingsDescriptions from "../utils/savingsDescriptions.json"
import useHomeImages from "../hooks/useHomeImages"
import { animate } from "../../../services/animations"

const HomeSavings = () => {
  const data = useHomeImages()

  useEffect(() => {
    animate({
      y: 50,
      type: "from",
      isScrollTrigger: true,
      target: ".fade-to-upper-cards",
      animateOn: ".fade-to-upper-cards",
    })
  }, [])

  return (
    <Hero size="small">
      <Container isCentered desktop={10} fullhd={8}>
        <h3 className="my-3 has-text-centered has-text-primary">
          Care and savings at your fingertips
        </h3>
        <div className="columns is-centered mb-3">
          {savingsDescriptions.map((item) => (
            <HomeMediaContainer
              title={item?.title}
              description={item?.description}
              image={data[item?.imageName]?.childImageSharp?.fixed}
              animationClass={"fade-to-upper-cards"}
            />
          ))}
        </div>
      </Container>
    </Hero>
  )
}

export default HomeSavings
