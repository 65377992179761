import React from "react"
import Hero from "layout/Hero"

const HomeGetStartedBanner = () => (
  <Hero size="small" color="light">
    <h5 className="mt-0 has-text-centered">Take charge of your health</h5>
    <h3 className="my-0 has-text-centered has-text-primary">
      Follow these steps to get started
    </h3>
  </Hero>
)

export default HomeGetStartedBanner
