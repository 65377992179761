import React from "react"
import Img from "gatsby-image"
import classNames from "classnames"

import styles from "../utils/staticPages.module.scss"

const HomeProcessArrow = ({ arrowImage, animationClass }) => (
  <div
    className={classNames(
      "column is-1 is-hidden-mobile",
      animationClass,
      styles["home__processArrow"]
    )}
  >
    <Img fixed={arrowImage} />
  </div>
)

export default HomeProcessArrow
