import { useStaticQuery, graphql } from "gatsby"

const useHomeImages = () => {
  const data = useStaticQuery(graphql`
    {
      saveTime: file(
        relativePath: { eq: "landing_page/landing_save-time--colored.png" }
      ) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      saveWorries: file(
        relativePath: { eq: "landing_page/landing_save-worries--colored.png" }
      ) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      saveMoney: file(
        relativePath: { eq: "landing_page/landing_save-money--colored.png" }
      ) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      register: file(
        relativePath: { eq: "landing_page/landing_register--teal.png" }
      ) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      order: file(
        relativePath: { eq: "landing_page/landing_order--teal.png" }
      ) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      receive: file(
        relativePath: { eq: "landing_page/landing_receive--teal.png" }
      ) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      arrow: file(relativePath: { eq: "process_triangle--grey.png" }) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      formulary: file(
        relativePath: {
          eq: "landing_page/landing_formulary--colored-sparkle.png"
        }
      ) {
        childImageSharp {
          fixed(width: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return data
}

export default useHomeImages
